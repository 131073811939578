.footer-wrapper {
  background: url("../images/en/orovera-frog-image.png") bottom left no-repeat;
  background-size: 400px;
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 150px;

  .mb-sm {
    margin-bottom: 16px;
  }

  .logo {
    flex: 1;
    max-width: 340px;

    .logo-wrapper {
      width: 172px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .footer-nav {
    flex: 1;
  }

  .top-footer {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .download {
      flex: 1;
      max-width: 248px;
    }

    .download-app-button:first-of-type {
      margin-bottom: 20px;
      max-width: 177px;
    }

    .download-app-button:last-of-type {
      max-width: 192px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      li a {
        color: #6b717b;
        line-height: 20px;
        font-size: 14px;
      }
    }
  }

  .bottom-footer {
    border-top: 1px solid #e8e8e8;
    margin-top: 60px;
    padding: 30px 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul.info {
      display: flex;
      gap: 40px;

      li,
      a {
        font-size: 16px;
        color: #727272;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

ul.social {
  display: flex;
  gap: 8px;

  li a {
    border-radius: 40px;
    border: 1px solid #e8e8e8;
    display: flex;
    width: 56px;
    height: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .footer-wrapper {
    background: url("../images/en/orovera-sign.png") bottom 250px left no-repeat;
    background-size: 400px;
  }

  .footer {
    margin-top: 60px;
    flex-direction: column;
    text-align: center;

    .logo {
      .logo-wrapper {
        margin: 0 auto;
      }
    }

    .bottom-footer {
      border-top: none;
      flex-direction: column;

      ul.info {
        gap: 20px;
        flex-direction: column;
      }

      ul.social {
        margin: 20px 0;
      }
    }
  }

  .top-footer {
    flex-direction: column;

    .download {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
}
