#terms-of-service {
  position: relative;
}

.terms-and-privacy {
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 210px;
  position: relative;
  width: 100%;

  &>.content {
    padding: 0 15px;
    width: 850px;


    &>img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 120px;
      z-index: -1;
      object-fit: contain;
    }

    &>header {
      &>.title-container {
        max-width: 620px;
        margin-bottom: 100px;

        &>.title {
          font-size: 70px;
          font-weight: 700;
        }
      }
    }

    &>.terms-and-privacy__item {
      margin-bottom: 60px;

      &>h3 {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .terms-and-privacy {
    &>.content {
      &>header>.title-container>.title {
        font-size: 60px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .terms-and-privacy {
    position: static;

    .sidebar {
      display: none;
    }

    &>.content {
      width: 850px;
      padding: 0;

      &>img {
        object-fit: cover;
      }

      &>header>.title-container>.title {
        font-size: 44px;
        padding: 0 15px;
      }

      &>.terms-and-privacy__item {
        padding: 0 15px;
      }
    }
  }
}

@media (max-width: 490px) {
  .terms-and-privacy {
    position: static;

    .sidebar {
      display: none;
    }

    &>.content {
      width: 100%;
      padding: 0;

      &>img {
        object-fit: cover;
      }

      &>header>.title-container>.title {
        font-size: 30px;
      }
    }
  }
}